import http from '@/utils/http';

/**
 * 内容管理
 */
export default {
  getList: {
    p: 'post,/manage/article/getlist',
    r: (data) => http({ url: '/manage/article/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/article/add',
    r: (data) => http({ url: '/manage/article/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/article/update',
    r: (data) => http({ url: '/manage/article/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/article/delete',
    r: (data) => http({ url: '/manage/article/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/article/getone',
    r: (data) => http({ url: '/manage/article/getone', method: 'post', data })
  }
};
