<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tableOption"
      @row-save="handleSubmit"
      @refresh-change="getList"
      @row-del="rowDel"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <template slot="menuLeft">
        <el-button
          icon="el-icon-check"
          type="primary"
          size="small"
          @click="addDialogForm"
          >添加文章</el-button
        >
      </template>
      <template slot-scope="{ type, size, row, index }" slot="menu">
        <el-button
          icon="el-icon-edit-outline"
          :size="size"
          :type="type"
          @click.stop="addDialogForm(row, index)"
          >编辑</el-button
        >
        <el-button
          icon="el-icon-delete"
          :size="size"
          :type="type"
          @click.stop="rowDel(row, index)"
          >删除</el-button
        >
      </template>
    </avue-crud>

    <el-drawer
      title="添加/编辑文章"
      :before-close="drawerClose"
      :visible.sync="dialogVisible"
      direction="ttb"
      size="100%"
    >
      <avue-form
        ref="form"
        v-model="formDa"
        :option="formOp"
        @submit="handleSubmit"
        @reset-change="delRedact"
      >
      </avue-form>
    </el-drawer>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
