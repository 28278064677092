import './list.scss';
import dataApi from '@/api/manage/article.js';
/*
* 文章列表
*  */
export default {
  name: 'article-list',
  components: {},
  data() {
    return {
      dataApi,
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      dialogVisible: false,
      formDa: {},
      formOp: {
        column: [
          {
            type: 'select',
            label: '所属分类',
            cascaderItem: [],
            span: 12,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'category_id',
            dataType: 'number',
            dicUrl: `${this.$baseUrl}general/get_article_category`,
            dicQuery: {},
            required: true,
            rules: [
              {
                required: true,
                message: '请选择所属分类'
              }
            ]
          },
          {
            type: 'radio',
            label: '文章来源',
            value: 1,
            prop: 'source',
            control: (val) => {
              if (val === 1) {
                return {
                  content: {
                    display: true
                  },
                  source_url: {
                    display: false
                  }
                };
              }
              if (val === 3) {
                return {
                  source_url: {
                    label: '视频feedId',
                    display: true,
                    tip: 'feedId唯一标识某一条视频，获取视频的feedId需要登录视频号助手，在「动态管理」模块可以复制自己发表的每个视频对应的feedId。'
                  },
                  content: {
                    display: false
                  },
                  intro: {
                    display: false
                  },
                  pageview: {
                    display: false
                  }
                };
              }
              return {
                content: {
                  display: false
                },
                intro: {
                  display: false
                },
                pageview: {
                  display: false
                },
                source_url: {
                  display: true
                }
              };
            },
            dicData: [
              {
                label: '本地',
                value: 1
              },
              {
                label: '外链',
                value: 2
              },
              {
                label: '视频号',
                value: 3
              }
            ],
            span: 12,
            display: true,
            required: true,
            rules: [
              {
                required: true,
                message: '请选择文章来源'
              }
            ]
          },
          {
            type: 'input',
            label: '文章标题',
            span: 24,
            display: true,
            prop: 'title',
            maxlength: 200,
            required: true,
            rules: [
              {
                required: true,
                message: '单行文本必须填写'
              }
            ],
            showWordLimit: true,
            placeholder: '文章标题'
          },
          {
            label: '缩略图',
            prop: 'images',
            type: 'upload',
            limit: 1,
            span: 24,
            addDisplay: true,
            editDisplay: true,
            hide: true,
            propsHttp: {},
            dataType: 'string',
            listType: 'picture',
            accept: 'image/png, image/jpeg',
            oss: '',
            tip: '只能上传jpg/png文件，且不超过500kb',
            action: `${this.$baseUrl}system/upload`,
            required: true,
            rules: [
              {
                required: true,
                message: '图片必须上传'
              }
            ]
          },
          {
            type: 'textarea',
            label: '文章简要',
            span: 24,
            display: true,
            prop: 'intro',
            maxlength: 500,
            minRows: 4,
            maxRows: 6,
            showWordLimit: true
          },

          {
            type: 'input',
            label: '作者',
            span: 12,
            display: true,
            prop: 'author',
            required: true,
            rules: [
              {
                required: true,
                message: '作者必须填写'
              }
            ]
          },
          {
            type: 'number',
            label: '阅读量',
            span: 12,
            display: true,
            prop: 'pageview',
            value: 0
          },
          {
            type: 'textarea',
            label: '外链地址',
            span: 24,
            prop: 'source_url',
            maxlength: 1000,
            minRows: 4,
            maxRows: 6,
            showWordLimit: true,
            display: false
          },
          {
            type: 'ueditor',
            component: 'AvueUeditor',
            label: '文章内容',
            span: 24,
            display: true,
            options: {
              customConfig: {
                showFullScreen: false, // 是否全屏
                pasteFilterStyle: false, // 关闭样式过滤
                excludeMenus: []
              },
              action: `${this.$baseUrl}system/upload`,
              oss: '',
              props: {},
              ali: {}
            },
            prop: 'content',
            required: true,
            rules: [
              {
                required: true,
                message: '富文本必须填写'
              }
            ]
          },
          {
            type: 'radio',
            label: '状态',
            dicData: [
              {
                label: '禁用',
                value: -1
              },
              {
                label: '启用',
                value: 1
              }
            ],
            value: 1,
            span: 12,
            display: true,
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true
          },
          {
            // 显示状态
            label: '显示状态',
            type: 'radio',
            value: 1,
            dicData: [
              {
                label: '显示',
                value: 1
              },
              {
                label: '隐藏',
                value: 0
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            span: 12,
            display: true,
            prop: 'is_show'
          },
          {
            label: '推荐位',
            type: 'select',
            span: 12,
            display: true,
            dicFlag: true,
            prop: 'recommend',
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_dict`,
            dicQuery: {
              dict_type: 'recommend'
            },
            dicMethod: 'post',
            dataType: 'string'
          },
          {
            type: 'number',
            label: '排序',
            span: 12,
            display: true,
            prop: 'sorting',
            value: 0
          }
        ],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 100,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '关闭',
        menuPosition: 'center'
      },
      tableData: [],
      tableOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        ...this.$store.getters.tableConfig,
        selection: false,
        delBtn: false,
        editBtn: false,
        column: [
          {
            type: 'select',
            label: '所属分类',
            cascaderItem: [],
            search: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'category_id',
            dataType: 'number',
            dicUrl: `${this.$baseUrl}general/get_article_category`,
            dicQuery: {}
          },
          {
            label: '文章标题',
            prop: 'title',
            search: true
          },
          {
            label: '缩略图',
            prop: 'images',
            type: 'img'
          },
          {
            type: 'radio',
            label: '文章来源',
            value: 1,
            prop: 'source',
            dicData: [
              {
                label: '本地',
                value: 1
              },
              {
                label: '外链',
                value: 2
              },
              {
                label: '视频号',
                value: 3
              }
            ],
            span: 12,
            display: true
          },
          {
            label: '作者',
            prop: 'author'
          },
          {
            label: '排序',
            prop: 'sorting'
          },
          {
            label: '状态',
            type: 'radio',
            search: true,
            dicData: [
              {
                label: '正常',
                value: 1
              },
              {
                label: '停用',
                value: -1
              }
            ],
            prop: 'status'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      searchData: {}
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    addDialogForm(row) {
      if (row.id !== undefined || row.id === 0) {
        this.getInfo(row);
      }
      this.dialogVisible = true;
    },
    drawerClose() {
      console.log(this.formDa);
      this.dialogVisible = false;
      this.$refs.form.resetForm();
    },
    handleSubmit(form, done) {
      if (form.id) {
        dataApi.update.r(form).then(() => {
          this.getList();
          this.drawerClose();
          done(form);
        });
      } else {
        dataApi.add.r(form).then(() => {
          this.getList();
          this.drawerClose();
          done(form);
        });
      }
      done();
    },
    delRedact() {
      this.dialogVisible = false;
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then(() => {
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    getInfo(row) {
      this.formDa = {};
      dataApi.getOne.r({ id: row.id }).then((res) => {
        this.formDa = res.data;
      });
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
